import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "./home/home.scss"

import ComingSoon from "./home/components/ComingSoon"



const IndexPage = () => (
  <Layout>
    <Seo title="Blue Mark Holdings | Home" />
    <ComingSoon />
  </Layout>
)

export default IndexPage

